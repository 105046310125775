'use client';

import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';

const DynamicGeoCountryLayer = dynamic(
  () => import(/* webpackChunkName: 'CMS_GeoCountryLayer' */ './GeoCountryLayer'),
  {
    ssr: false,
  },
);

export const GeoCountryLayerLoader = ({
  geoCountryHeaderCode,
}: {
  geoCountryHeaderCode: string | null;
}) => {
  const searchParams = useSearchParams();

  const geoCountryCode =
    !Array.isArray(geoCountryHeaderCode) && searchParams?.get('skipCountryLayer') !== 'true'
      ? geoCountryHeaderCode
      : undefined;

  return <DynamicGeoCountryLayer geoCountryCode={geoCountryCode ?? undefined} />;
};
